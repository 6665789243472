import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import styles from './OneBarChart.module.scss';

const OneBarChart = ({ value, max, tooltip }) => {
  const series = [
    {
      data: [{
        x: '',
        y: value,
      }],
    },
  ];

  const options = {
    chart: {
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    colors: ['#109cf1'],
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    plotOptions: {
      bar: {
        expandOnClick: false,
        borderRadius: 4,
        horizontal: true,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      followCursor: true,
      fixed: {
        position: 'topRight',
      },
      style: {
        fontSize: '12px',
        color: '#109CF1',
      },
      custom: () => (
        '<div style="padding: 8px; font-size: 12px; color: #6A707E; font-weight: 500;">'
        + '<div>'
        + `${tooltip}`
        + '</div>'
      + '</div>'
      ),
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => '',
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      min: 0,
      max: Math.ceil(max),
      labels: {
        show: false,
        style: {
          width: 0,
        },
      },
    },
  };

  return (
    <div className={`${styles.chart_container}`}>
      <div className={`${styles.chart}`}>
        <Chart
          type="bar"
          options={options}
          series={series}
          height={55}
        />
      </div>
    </div>
  )
}

export default OneBarChart
