import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { localeStorageCache } from '../../../utils/localeStorage';
import { showErrorMessage } from '../../base/Notifications';

const getAuthToken = () => {
  const authKey = localeStorageCache.getAuth0Key();
  const authData = localeStorageCache.get(authKey);

  if (authData && authData.body) {
    const { id_token: idToken } = authData.body;
    return idToken;
  }
  return null;
};

const useWebSocket = (url, payload, skip, asset, filter = null) => {
  const [data, setData] = useState([]);
  const [fullMessage, setFullMessage] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef(null);
  const messageBuffer = useRef('');
  const prevPayload = useRef(null);

  const closeConnection = useCallback(() => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.close();
      socketRef.current = null;
    }
  }, []);

  useEffect(() => {
    const token = getAuthToken();
    const baseURL = import.meta.env.VITE_APP_SERVER_DOMAIN;
    const urlfull = `${baseURL.startsWith('https') ? baseURL.replace('https', 'wss')
      : baseURL.replace('http', 'ws')}${url}`;
    const currentPayload = JSON.stringify(payload);

    const newPayload = JSON.stringify(payload) !== JSON.stringify(prevPayload.current);
    if (skip || !newPayload) {
      return;
    }

    if (newPayload) {
      setData([]);
      setFullMessage('');
      setIsConnected(false);
      messageBuffer.current = '';
      prevPayload.current = currentPayload;
      closeConnection();
    }

    if (url && payload && token) {
      const socket = new WebSocket(urlfull, ['Authorization', token]);
      socketRef.current = socket;

      socket.onopen = () => {
        socket.send(JSON.stringify(payload));
        setData([]);
        setFullMessage('');
        setIsConnected(false);
        messageBuffer.current = '';
        prevPayload.current = currentPayload;
      };

      socket.onmessage = (event) => {
        const messageData = event.data;
        messageBuffer.current += messageData;
        setData((prevData) => [...prevData, messageData]);
        if (messageBuffer.current.trim().endsWith('</full_analysis>')) {
          setFullMessage(messageBuffer.current);
        }
      };

      socket.onerror = () => {
        showErrorMessage('There was an error generating the AI Overview. Try again later');
      };

      socket.onclose = () => {
        setFullMessage(messageBuffer.current);
        setIsConnected(false);
      };

      return closeConnection;
    }
  }, [skip, filter, asset]);

  return { data, fullMessage, isConnected };
};

export default useWebSocket;
