import React from 'react';
import styles from './ComingSoon.module.scss';
import ComingSoonImg from '../../assets/images/coming_soon.svg';

const ComingSoon = () => (
  <div className={`${styles.wrapper} d-flex flex-column align-items-center text-center`}>
    <span>Coming Soon</span>
    <span>We are currently working hard building this page</span>
    <ComingSoonImg />
  </div>
);

export default ComingSoon;
