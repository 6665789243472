import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setAssetSlug, setSelectedAsset } from '../../../../store/reducers/app';
import ItemPreview from '../../ItemPreview';
import Collection from '../../../../assets/icons/layers.svg';
import styles from '../NotableInvestments.module.scss';
import Tooltip from '../../Tooltip';

const Name = ({ isNFT, item, owned }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`${styles.chart_name} d-flex`}
      data-for={isNFT
        ? `preview_${item.contract_addresses}_${item.blockchain || item.platform}`
        : item.coin_id
          ? `preview_${item.coin_id}`
          : null}
      data-tip="show"
    >
      {isNFT && item?.contract_addresses?.length > 1
        ? (
          <>
            <span
              className={`${styles.notable_name} ${owned ? styles.owned : ''} text-truncate cursor-pointer`}
              role="presentation"
              data-bs-toggle="modal"
              data-bs-target="#exModal"
              data-for={item.opensea_slug}
              data-tip="show"
              onClick={() => dispatch(setAssetSlug({
                slug: item.opensea_slug,
                name: item.name,
                logo: item.logo,
                symbol: item.symbol,
                network: item.blockchain || item.platform,
              }))}
            >
              {item.name}
            </span>
            <ItemPreview
              id={item.opensea_slug}
              data={{
                address: item.contract_addresses,
                type: 'nft',
                blockchain: item.blockchain || item.platform,
                img: item.logo,
                name: item.name,
              }}
              collection={item.opensea_slug}
            />
            <div
              className={`d-flex align-items-center px-1 ${styles.collection}`}
              data-for="collection"
              data-tip
            >
              <Collection />
            </div>
            <Tooltip
              id="collection"
              info="This collection consists of multiple sub-collections"
            />
          </>
        ) : (
          <Link
            className={`${styles.img_wrapper} cursor-pointer`}
            onClick={() => dispatch(setSelectedAsset({
              type: isNFT ? 'nft' : 'token',
              name: item.name,
              logo: item.logo,
              symbol: item.symbol?.toUpperCase(),
              network: item.blockchain || item.platform,
            }))}
            to={isNFT
              ? `/nfts/${item.blockchain || item.platform}/${item.contract_addresses}`
              : `/coins/${item.coin_id}`}
            role="presentation"
          >
            <span
              className={`${styles.notable_name} ${owned ? styles.owned : ''} text-truncate cursor-pointer`}
              role="presentation"
              onClick={() => dispatch(setSelectedAsset({
                type: isNFT ? 'nft' : 'token',
                name: item.name,
                logo: item.logo,
                symbol: item.symbol?.toUpperCase(),
                network: item.blockchain || item.platform,
              }))}
            >
              {item.name}
            </span>
          </Link>
        )}
    </div>
  );
};

export default Name;
