import { convertNumber, convertToReadableFormat } from './NumberConverterTool';
import { ethFormat } from '../utils/singleAssetPage/parseData';
import { getTokenUnit } from '../utils/chains';

export const convertSmallChart = (totalVolume) => {
  const volumeDate = totalVolume.map((item) => item[0]);
  const volumeData = totalVolume.map((item) => Math.round(item[1]));

  return {
    series: [
      {
        name: '',
        data: volumeData,
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
        colors: ['#109CF1'],
      },
      fill: {
        type: 'gradient',
        colors: ['#109CF1'],
        gradient: {
          opacityFrom: 0.2,
          opacityTo: 0.3,
          stops: [0, 110],
        },
        pattern: {
          style: 'verticalLines',
        },
      },
      tooltip: {
        marker: {
          show: false,
        },
        x: {
          show: false,
        },
        y: {
          formatter: (y) => convertToReadableFormat(y),
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        axisTicks: {
          show: false,
        },
        categories: volumeDate,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            width: 0,
          },
        },
      },
    },
  };
};

export const converterChart = (historyPrice, days, name, checkbox, BTC, symbol) => {
  const arrayDate = historyPrice.map((item) => {
    if (days === '1') {
      return `${new Date(item[0]).toString().split(' ').slice(4, 5)
        .join(':')
        .split(':')
        .slice(0, 1)
        .join(' ')}:00`;
    } if (days === '365') {
      return new Date(item[0]).toString().split(' ').slice(1, 4)
        .join(' ');
    }
    return new Date(item[0]).toString().split(' ').slice(1, 3)
      .join(' ');
  });

  const arrayPriceFilter = historyPrice.map((item) => +item[1]);
  const BTCFilter = BTC.map((item) => Math.round(item[1]));

  const arrayPrice = arrayPriceFilter.length > BTCFilter.length
    ? arrayPriceFilter.slice(0, BTCFilter.length)
    : arrayPriceFilter;
  const BTCPrices = arrayPrice.length < BTCFilter.length ? BTCFilter.slice(0, arrayPrice.length) : BTCFilter;

  const seriesArray = checkbox
    ? [{
      name: name ? `Price ${symbol ? `$${symbol.toUpperCase()}` : name.toUpperCase()}` : '?',
      data: arrayPrice,
    },
    {
      name: checkbox ? 'Price BTC' : '',
      data: checkbox ? BTCPrices : [],
    },
    ] : [
      {
        name: `${symbol ? `Price $${symbol.toUpperCase()}` : name.toUpperCase()}`,
        data: arrayPrice,
      },
    ];
  return {
    series: seriesArray,
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easein',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            xaxis: {
              tickAmount: 10,
            },
          },
        },
        {
          breakpoint: 500,
          options: {
            xaxis: {
              tickAmount: 6,
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },
      subtitle: {
        text: '',
      },
      title: {
        text: checkbox ? 'Price BTC' : '',
        align: 'right',
        offsetY: 10,
        style: {
          color: '#90A0B7',
          fontWeight: 500,
          fontSize: '14px',
        },
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: arrayDate,
        tickAmount: 18,
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
      },
      colors: ['#109CF1', '#C2CFE0'],
      yaxis: [{
        title: {
          text: 'USD',
          style: {
            color: '#90A0B7',
          },
        },
        axisBorder: {
          show: true,
          offsetX: -1,
        },
        labels: {
          formatter: (y) => {
            if (y > 1000) {
              return `${(y / 1000).toFixed(1)}K`;
            } if (y > 100) {
              return y.toFixed(2);
            } if (y > 1) {
              return y.toFixed(4);
            } if (y > 0.000001) {
              return y.toFixed(5);
            }
            return `0.00..${(+String(y)
              .split('').slice(0, String(y).split('').length - 4)
              .join('') * 10).toFixed(0)}`;
          },
        },
        tickAmount: 8,
        min: Math.min(...arrayPrice) / 1.25,
        max: Math.max(...arrayPrice) + Math.min(...arrayPrice) / 15,
      },
      {
        opposite: true,
        min: Math.min(...BTCPrices) / 1.25,
        max: Math.max(...BTCPrices) + Math.min(...BTCPrices) / 4,
        axisBorder: {
          show: true,
        },
        labels: {
          show: checkbox,
          formatter: (y) => (`${(y / 1000).toFixed(0)}K`),
        },
      }],
      tooltip: {
        y: {
          formatter: (y) => {
            if (y > 1000) {
              return `$${convertNumber(y.toFixed(0))}`;
            }
            return `$${ethFormat(y)}`;
          },
        },

      },
      legend: {
        show: false,
      },
    },
  };
};

const padDataWithKnownValues = (sortedData, startDate, endDate) => {
  const dataArray = [];
  let lastKnownData = { avg_price_eth: 0 };
  const dataMap = new Map(sortedData.map((item) => [item.date, item]));

  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    const dateString = d.toISOString().substr(0, 10);

    if (dataMap.has(dateString)) {
      lastKnownData = dataMap.get(dateString);
      dataArray.push({
        date: dateString,
        avgPrice: lastKnownData.avg_price_eth,
        volume: lastKnownData.volume_eth,
      });
    } else {
      dataArray.push({
        date: dateString,
        avgPrice: lastKnownData.avg_price_eth,
        volume: 0,
      });
    }
  }
  return dataArray.reverse();
};

export const nftChart = (currency, data, duration, exchange, blockchain) => {
  if (!data || !data.length) return null;

  const sortedData = [...data].sort((a, b) => new Date(a.date) - new Date(b.date));

  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(endDate.getDate() - 179);

  const dataArray = padDataWithKnownValues(sortedData, startDate, endDate);

  const periodData = () => {
    switch (duration) {
      case '7':
        return dataArray.slice(0, 7);
      case '30':
        return dataArray.slice(0, 30);
      default:
        return dataArray;
    }
  };

  const filterCurrentSevenDayValue = dataArray.slice(1, 8)
    .map((item) => item.avgPrice).filter(((value) => !!value));
  const avgOfSevenDay = filterCurrentSevenDayValue.length
    ? filterCurrentSevenDayValue.reduce((acc, elem) => acc + elem, 0) / filterCurrentSevenDayValue.length
    : 0;

  const filterCurrentMonthValue = dataArray.slice(1, 31)
    .map((item) => item.avgPrice).filter(((value) => !!value));
  const avgOfMonth = filterCurrentMonthValue.length
    ? filterCurrentMonthValue.reduce((acc, elem) => (acc + elem)) / filterCurrentMonthValue.length
    : 0;

  const filterCurrentSixMonthValue = dataArray.slice(1, dataArray.length)
    .map((item) => item.avgPrice).filter(((value) => !!value));
  const avgOfSixMonth = filterCurrentSixMonthValue.length
    ? filterCurrentSixMonthValue.reduce((acc, elem) => (acc + elem)) / filterCurrentSixMonthValue.length
    : 0;
  const volumeAvailable = Math.max(...periodData().map((item) => item.volume)) > 0

  return ({
    sevenDay: avgOfSevenDay !== 0
      ? (((data[0].avg_price_eth - avgOfSevenDay) / avgOfSevenDay)) * 100
      : 0,
    month: avgOfMonth !== 0
      ? ((data[0].avg_price_eth - avgOfMonth) / avgOfMonth) * 100
      : 0,
    sixMonth: avgOfSixMonth !== 0
      ? ((data[0].avg_price_eth - avgOfSixMonth) / avgOfSixMonth) * 100
      : 0,
    series: [
      {
        name: 'Average Price',
        data: [...periodData().map((item) => item.avgPrice).reverse()],
      },
      {
        name: 'Volume',
        data: [...periodData().map((item) => item.volume).reverse()],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easein',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          left: 30,
        },
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            xaxis: {
              tickAmount: 10,
            },
          },
        },
        {
          breakpoint: 500,
          options: {
            xaxis: {
              tickAmount: 6,
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },
      title: {
        text: 'Average Price',
        align: 'left',
        offsetY: 20,
        style: {
          color: '#90A0B7',
          fontWeight: 500,
          fontSize: '14px',
        },
      },
      subtitle: volumeAvailable ? {
        text: 'Volume',
        align: 'right',
        offsetY: 20,
        style: {
          color: '#90A0B7',
          fontWeight: 500,
          fontSize: '14px',
        },
      } : {},
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [...periodData().map((item) => item.date).reverse()],
        tickAmount: periodData().length <= 7 ? periodData().length : 18,
        labels: {
          rotate: -45,
          rotateAlways: true,
          showDuplicates: false,
          hideOverlappingLabels: true,
          formatter: (x) => new Date(x).toUTCString().substr(4, 12),
        },
      },
      colors: ['#109CF1', '#C2CFE0'],
      yaxis:
        volumeAvailable ? [{
          title: {
            text: currency,
            style: {
              color: '#90A0B7',
            },
          },
          axisBorder: {
            show: true,
          },
          labels: {
            formatter: (y) => (currency === 'ETH' ? ethFormat(y) : convertToReadableFormat(Math.round(y * exchange))),
          },
          tickAmount: 8,
          min: Math.min(...periodData().map((item) => item.avgPrice)) / 1.25,
          max: Math.max(...periodData().map((item) => item.avgPrice))
              + Math.min(...periodData().map((item) => item.avgPrice)) / 15,
      },
      {
        opposite: true,
        title: {
          style: {
            color: '#90A0B7',
          },
        },
        min: Math.min(...periodData().map((item) => item.volume)) / 1.25,
        max: Math.max(...periodData().map((item) => item.volume))
            + Math.min(...periodData().map((item) => item.volume)) / 4,
        axisBorder: {
          show: true,
        },
        labels: {
          formatter: (y) => (currency === 'ETH'
            ? Math.round(y)
            : convertToReadableFormat(Math.round(y * exchange))),
        }
      }] : [{
              title: {
                text: currency,
                style: {
                  color: '#90A0B7',
                },
              },
              axisBorder: {
                show: true,
              },
              labels: {
                formatter: (y) => (currency === 'ETH' ? ethFormat(y) : convertToReadableFormat(Math.round(y * exchange))),
              },
              tickAmount: 8,
              min: Math.min(...periodData().map((item) => item.avgPrice)) / 1.25,
              max: Math.max(...periodData().map((item) => item.avgPrice))
                + Math.min(...periodData().map((item) => item.avgPrice)) / 15,
      }],
      tooltip: {
        y: {
          formatter: (val, opts) => (opts.seriesIndex === 0) ? (currency === 'USD'
              ? `$${val * exchange < 100 ? +(val * exchange).toFixed(2) 
                : convertNumber(+(val * exchange).toFixed(0))}`
              : `${ethFormat(val)} ${getTokenUnit(blockchain)}`)
            : (val !== null ? `${val}K` : null)
        },
      },
      legend: {
        show: false,
      },
    },
  });
};
