import React from 'react';
import { useLocation } from 'react-router-dom';
import { ethers } from 'ethers';
import NotableInvestments from '../../../components/ui/NotableInvestments';
import { profilesApi } from '../../../api/profiles';
import Interests from './Interests';
import MultiChainActivity from './MultiChainActivity';
import CircleChart from '../../../components/ui/CircleChart';
import { isWalletAddress } from '../../../utils/supportedBlockchains';

const Portfolio = ({
  total, distributionData, address, multiChainData, isLoading,
}) => {
  const { pathname } = useLocation();

  const {
    currentData: notableInvestments,
    isLoading: isNotableInvestmentsLoading,
    isFetching: isNotableInvestmentsFetching,
  } = profilesApi.useGetWalletNotableInvestmentsQuery(
    isWalletAddress(pathname.split('/')[2])
      ? `?address=${pathname.split('/')[2]}`
      : `?alid=${pathname.split('/')[2]}&address=${address || ''}`,
    {
      skip: !isWalletAddress(pathname.split('/')[2]) && !address,
    },
  );

  const {
    currentData: walletInterests,
    isLoading: isWalletInterestsLoading,
    isFetching: isWalletInterestsFetching,
  } = profilesApi.useGetWalletInterestsQuery(
    isWalletAddress(pathname.split('/')[2])
      ? `?address=${pathname.split('/')[2]}`
      : `?alid=${pathname.split('/')[2]}&address=${address || ''}`,
    {
      skip: !isWalletAddress(pathname.split('/')[2]) && !address,
    },
  );

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <NotableInvestments
            text="tokens"
            isLoading={isNotableInvestmentsLoading || isNotableInvestmentsFetching}
            data={notableInvestments?.tokens}
            holders={total}
            // info="Relevant tokens held by audience"
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0">
          <NotableInvestments
            text="NFTs"
            isLoading={isNotableInvestmentsLoading || isNotableInvestmentsFetching}
            data={notableInvestments?.nfts}
            holders={total}
            // info="Relevant NFTs held by audience"
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-xl-0">
          <CircleChart
            title="Portfolio Distribution"
            data={distributionData.filter((token) => token.percent > 0)}
            sort="desc"
          />
        </div>
      </div>
      <div style={{ marginTop: '32px' }}>
        <MultiChainActivity
          multiChainData={multiChainData}
          isLoading={isLoading}
        />
      </div>
      <div>
        <Interests
          isLoading={isWalletInterestsLoading}
          isFetching={isWalletInterestsFetching}
          walletInterests={walletInterests}
        />
      </div>
    </>
  );
};

export default Portfolio;
