import React, { useEffect, useState } from 'react';
import { counter } from '../../../../tools/Counter';
import Table from '../../../../components/base/Table';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import { getEthPrice } from '../../../../utils/values';
import styles from './List.module.scss';
import Gmail from '../../../../assets/icons/domains/gmail.svg';
import GoogleAds from '../../../../assets/icons/domains/google_ads.svg';
import LinkedIn from '../../../../assets/icons/domains/linkedin.svg';
import Search from '../../../../assets/icons/domains/search.svg';
import Twitter from '../../../../assets/icons/domains/twitter.svg';
import Youtube from '../../../../assets/icons/domains/youtube.svg';

const domainIcons = {
  'gmail.com': Gmail,
  'googleadservices.com': GoogleAds,
  linkedin: LinkedIn,
  Search,
  twitter: Twitter,
  'x.com': Twitter,
  Twitter,
  Google: GoogleAds,
  LinkedIn,
  'linkedin.com': LinkedIn,
  'lnkd.in': LinkedIn,
  Youtube,
  'youtube.com': Youtube,
};

const getDomain = (url) => {
  const match = url?.match(/(?:https?:\/\/)?(?:www\.)?([^\\/]+)/i);
  const domain = match ? match[1].split('.').slice(-2).join('.') : url;
  return domain;
};

const getDomainIcon = (domain) => {
  const IconComponent = domainIcons[domain] || null;
  return IconComponent ? (
    <IconComponent style={{
      width: '25px', height: '25px', marginRight: '8px', marginBottom: '0.25em',
    }}
    />
  ) : (
    <img
      src={`https://${domain}/favicon.ico`}
      alt={`${domain} icon`}
      style={{
        width: '25px', height: '25px', marginRight: '8px', marginBottom: '0.25em',
      }}
      onError={(e) => { e.target.style.display = 'none'; }}
    />
  );
};

const List = ({
  data, isLoading, page, setPage, orderBy, setOrderBy, order, setOrder, limit, setLimit, title, showNewVisitorsColumn,
}) => {
  const [tableData, setTableData] = useState([]);

  const header = [
    {
      field: '',
      title: '#',
      width: '32px',
    },
    {
      field: 'source_field',
      title,
    },
    {
      field: 'unique_visitors',
      title: 'Visitors',
    },
    ...(showNewVisitorsColumn
      ? [{
        field: 'new_visitors',
        title: 'New Visitors',
      }]
      : []),
    {
      field: 'wallet_holders',
      title: 'With Wallets',
    },
    {
      field: 'web3_adoption',
      title: 'Web3 Adoption',
    },
    {
      field: 'wallets_connected',
      title: 'Wallets Connected',
    },
    {
      field: 'median_buying_power',
      title: 'Median Buying Power',
    },
    {
      field: 'visitors_converted',
      title: 'Converted',
    },
  ];

  useEffect(() => {
    if (data?.data.length) {
      const temp = data?.data?.map((elem, index) => ({
        pageNumber: (
          <span className={styles.number}>{(page - 1) * limit + index + 1}</span>
        ),
        source_field: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
              {getDomainIcon(getDomain(elem.source_field))}
              <span className={`${styles.content_name} text-truncate`}>
                {getDomain(elem.source_field) || 'Unknown'}
              </span>
            </div>
          </div>
        ),
        unique_visitors: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {ethFormat(elem.unique_visitors, 1)}
              </span>
            </div>
          </div>
        ),
        ...(showNewVisitorsColumn && {
          new_visitors: (
            <div className={`${styles.elements} d-flex align-items-center gap-2`}>
              <span>{ethFormat(elem.new_visitors, 1)}</span>
              <span>
                {(elem.new_visitors / elem.unique_visitors) * 100 >= 0.01
                  ? ethFormat((elem.new_visitors / elem.unique_visitors) * 100, 1)
                  : '<0.01'}
                %
              </span>
            </div>
          ),
        }),
        wallet_holders: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {ethFormat(elem.wallet_holders, 1)}
              </span>
            </div>
          </div>
        ),
        web3_adoption: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {elem.web3_adoption}
              </span>
            </div>
          </div>
        ),
        wallets_connected: (
          <div className={`${styles.elements} d-flex align-items-baseline gap-2`}>
            <span>{ethFormat(elem.wallets_connected, 1)}</span>
            <span>
              {(elem.wallets_connected / elem.wallet_holders) * 100 >= 0.01
                ? ethFormat((elem.wallets_connected / elem.wallet_holders) * 100, 1)
                : '<0.01'}
              %
            </span>

          </div>
        ),
        median_buying_power: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {getEthPrice(elem?.median_buying_power, true)}
              </span>
            </div>
          </div>
        ),
        visitors_converted: (
          <div className={`${styles.elements} d-flex align-items-baseline gap-2`}>
            <span>{ethFormat(elem.visitors_converted, 1)}</span>
            <span>{(elem.conversion_rate)}</span>
          </div>
        ),
      }));
      setTableData(temp);
    } else {
      setTableData([]);
    }
  }, [data, page, limit, showNewVisitorsColumn]);

  return (
    <>
      <div className="d-flex flex-column align-self-start">
        <div className={styles.title}>
          {`${title} List`}
        </div>
        <div className="d-flex w-100 justify-content-between align-items-center mb-4">
          <div className={styles.content_info}>
            {counter(data?.count, page, limit)}
          </div>
        </div>
      </div>
      <div className="w-100">
        <Table
          total={data?.count || 0}
          data={tableData}
          desc={order}
          setDesc={setOrder}
          ordering={orderBy}
          setOrdering={setOrderBy}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          headers={header}
          isLoading={isLoading}
          min={25}
        />
      </div>
    </>
  );
};

export default List;
