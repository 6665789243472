import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { userApi } from '../../../api/user';
import { setAssetSlug, setSelectedAsset } from '../../../store/reducers/app';
import { convertToReadableFormat } from '../../../tools/NumberConverterTool';
import Loader from '../../base/Loader';
import NoData from '../../base/NoData';
import uniqueId from '../../../utils/uniqueId';
import AssetImg from './AssetImg';
import AddressesModal from './AddressesModal';
import styles from './NotableInvestments.module.scss';
import ItemPreview from '../ItemPreview';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import CardTitle from '../CardTitle';
import Name from './Name';
import Value from './Value';
import OneBarChart from '../../base/OneBarChart'

const NotableInvestments = ({
  text, symbol, data, isLoading, holders, info,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [myAssets, setMyAssets] = useState([]);

  const { data: assets } = userApi.useGetAssetsQuery();

  useEffect(() => {
    if (assets) {
      setMyAssets(assets.filter((elem) => elem.item_type === 'asset' && elem.name).map((elem) => elem.name));
    } else {
      setMyAssets([]);
    }
  }, [assets]);

  const isWallet = useMemo(
    () => location.pathname.includes('profile'),
    [location.pathname],
  );

  const maxValue = useMemo(() => (data && holders) 
    ? Math.max(...data.map((item) => ((item.number_holders) / holders) * 100))
    : null, [data, holders]);

  const filterNumberLessOne = useMemo(() => (data && holders) 
    ? Math.max(...data
      .map((elem) => ((elem.number_holders) / holders) * 100)
      .filter((elem) => elem < 1))
    : null, [data, holders]);

    const getValue = useCallback((value) => {
      if (holders === 0) return null;
      const res = (value / holders) * 100;
      return res < 1
        ? Math.ceil(filterNumberLessOne)
        : res > 99
          ? Math.ceil(maxValue)
          : res === maxValue
            ? Math.ceil(res) 
            : res;
    }, [data, holders])

  const getResult = useCallback((value) => {
    if (holders === 0) return null;
    const res = (value / holders) * 100;
    if (res < 1) {
      return '<1%';
    }
    if (res < 10) {
      return `${+res.toFixed(2)}%`;
    }
    if (res <= 99) {
      return `${+res.toFixed(1)}%`;
    }
    if (res === 100) {
      return '100%';
    }
    return '>99%';
  }, [data, holders])

  const getTooltip = useCallback((qty, symb, value) => {
    return '<div>'
      + `${!isWallet ? 'Audience collectively' : 'Profile'} owns`
      + '</div>'
      + '<div>'
      + `${qty > 0
        ? convertToReadableFormat(qty) : '?'} ${symb ? symb?.toUpperCase()
        : ''}
      ${text.includes('NFT') ? 'NFTs,' : 'tokens,'} worth $${convertToReadableFormat(value)}`
      + '</div>'
      + '<div>'
      + `${text.includes('NFT') ? 'at floor price' : ''}`
      + '</div>';
  }, [data])

  return (
    <div className={`${styles.wrapper}`}>
      <AddressesModal />
      <CardTitle title={`Notable ${capitalizeFirstLetter(text)} in Portfolio${symbol ? 's' : ''}`} info={info} />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center p-5 h-75">
          <Loader />
        </div>
      ) : (
        holders && holders !== 0 && data && data.length ? (
          <>
            {data.map((item) => (
              <div className={styles.row} key={uniqueId('Investments')}>
                {(text.includes('NFT') && item?.contract_addresses?.length > 1) ? (
                  <div
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#exModal"
                    className={`${styles.img_wrapper} cursor-pointer position-relative`}
                    onClick={() => dispatch(setAssetSlug({
                      slug: item.opensea_slug,
                      name: item.name,
                      logo: item.logo,
                      symbol: item.symbol,
                      network: item.blockchain || item.platform,
                    }))}
                    data-for={item.opensea_slug}
                    data-tip="show"
                  >
                    <AssetImg text={text} type={text.includes('NFT') ? 'nft' : 'token'} logo={item.logo} />
                  </div>
                ) : (
                  <Link
                    className={`${styles.img_wrapper} cursor-pointer`}
                    onClick={() => dispatch(setSelectedAsset({
                      type: text.includes('NFT') ? 'nft' : 'token',
                      name: item.name,
                      logo: item.logo,
                      symbol: item.symbol?.toUpperCase(),
                      network: item.blockchain || item.platform,
                    }))}
                    to={text.includes('NFT')
                      ? `/nfts/${item.blockchain || item.platform}/${item.contract_addresses}`
                      : `/coins/${item.coin_id}`}
                    role="presentation"
                    data-for={text.includes('NFT')
                      ? `preview_${item.contract_addresses}_${item.blockchain || item.platform}`
                      : text.includes('tokens')
                        ? `preview_${item.coin_id}`
                        : null}
                    data-tip="show"
                  >
                    <AssetImg text={text} type={text.includes('NFT') ? 'nft' : 'token'} logo={item.logo} />
                  </Link>
                )}

                <div className={styles.row_right}>
                  <div className={styles.text}>
                    <Name
                      isNFT={text.includes('NFT')}
                      item={item}
                      owned={myAssets.includes(item.name)}
                    />
                    {text.includes('NFT') && item?.contract_addresses?.length <= 1
                      ? (
                        <ItemPreview
                          id={`preview_${item.contract_addresses}_${item.blockchain || item.platform}`}
                          data={{
                            address: item.contract_addresses,
                            type: 'nft',
                            blockchain: item.blockchain || item.platform,
                            img: item.logo,
                            name: item.name,
                          }}
                        />
                      )
                      : null}
                    {text.includes('tokens')
                      ? (
                        <ItemPreview
                          id={`preview_${item.coin_id}`}
                          data={{
                            id: item.coin_id,
                            type: 'coin',
                          }}
                        />
                      )
                      : null}
                    <Value
                      value={getResult(item.number_holders)}
                      owned={myAssets.includes(item.name)}
                    />
                  </div>
                  <OneBarChart
                    value={getValue(item.number_holders)}
                    max={maxValue}
                    tooltip={getTooltip(item.token_qty_decimal, item.symbol, item.value || item.wallet_value)}
                    />
                  </div>
                </div>
            ))}
            {symbol && (
              <div className="d-flex">
                <div className="d-flex align-items-center gap-2 asset-wrap mt-1">
                  <div className="dot" />
                  <span>
                    {`Share of ${symbol
                      ? (symbol === 'audience' || symbol === 'detected wallets'
                        ? `${symbol} holding`
                        : `$${symbol?.toUpperCase()} holders also holding`)
                      : ''}`}
                  </span>
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <NoData />
          </div>
        ))}
    </div>
  );
};

export default React.memo(NotableInvestments);
