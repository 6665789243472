import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigationType, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { coinApi } from '../../api/coin';
import Price from '../../assets/images/brand/values.svg?url';
import MarketCap from '../../assets/images/asset/MarketCap.svg?url';
import Box from '../../assets/images/asset/boxes.svg?url';
import Members from '../../assets/images/asset/relHolders.svg?url';
import {
  bitcoinPrice,
  dailyVolume,
  getHistoryVolume,
  historyPrices,
} from '../../store/reducers/price';
import {
  getUser, isLoadingUser,
} from '../../store/reducers/user';
import { ethFormat } from '../../utils/singleAssetPage/parseData';
import { supportedBlockchains } from '../../utils/supportedBlockchains';
import InvestorProfile from './InvestorProfile';
import InvestPortfolio from './InvestPortfolio';
import { convertNumber } from '../../tools/NumberConverterTool';
import Holders from './Holders';
import SingleCoinTitle from '../../components/ui/TitleSection/Titles/SingleCoinTitle';
import { assetData } from './dummy-data';
import Market from '../../components/ui/Market';
import { resetSelectedAsset } from '../../store/reducers/app';
import './Asset.scss';
import { selectTabPages, setTabPages, selectFilterOption } from '../../store/reducers/coin';
import Exchanges from './Exchanges';
import DappUsage from './DappUsage';
import Filters from './Filters';
import {
  getCardValue, getEthNumber, getFormatedNumber, getGrowth, getPrice,
} from '../../utils/values';
import useWebSocket from '../../components/hooks/app/useWebSocket';

const DEFAULT_TAB = 'Audience Profile';

const Asset = () => {
  const dispatch = useDispatch();
  const { name } = useParams();
  const { pathname, search } = useLocation();
  const navType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(null);
  const [days, setDays] = useState('30');
  const [isSupportBlockchain, setIsSupportBlockchain] = useState(null);
  const [dailyVolumeData, setDailyVolumeData] = useState(undefined);
  const [dailyVolumeDataChart, setDailyVolumeDataChart] = useState(undefined);
  const [titleSectionData, setTitleSectionData] = useState(null);
  const [marketData, setMarketData] = useState({});
  const [pages, setPages] = useState([]);
  const [networks, setNetworks] = useState([]);

  const filterOption = useSelector(selectFilterOption);
  const historyVolume = useSelector(getHistoryVolume);
  const tabPages = useSelector(selectTabPages);
  const getPage = useCallback((pageLabel) => pages.find((page) => page.label === pageLabel), [pages]);
  const [aiOverview, setAiOverview] = useState({});
  const user = useSelector(getUser);
  const userLoading = useSelector(isLoadingUser);
  const [shouldConnect, setShouldConnect] = useState(false);

  const { data, fullMessage, isConnected } = useWebSocket(
    shouldConnect ? 'ws/ft/ai-overview/' : null,
    shouldConnect ? { coin_id: name } : null,
    !shouldConnect,
    name,
  );

  useEffect(() => {
    if (!userLoading
      && user
      && user.ai_explanations) {
      setShouldConnect(true);
    }
  }, [userLoading]);

  useEffect(() => {
    if (isConnected) {
      setShouldConnect(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (data.length > 0 && !fullMessage) {
      setAiOverview((prev) => ({
        ...prev,
        data,
      }));
    } else if (fullMessage) {
      setAiOverview((prev) => ({
        ...prev,
        fullJSONMessage: fullMessage,
      }));
    } else {
      setAiOverview({
        data: null,
        fullJSONMessage: null,
      });
    }
  }, [data, fullMessage]);

  const {
    currentData: asset,
    isLoading,
    isFetching,
  } = coinApi.useGetCoinByIdQuery(name);

  const {
    currentData: history,
    isLoading: historyIsLoading,
    isFetching: historyIsFetching,
    isSuccess,
  } = coinApi.useGetCoinFromMarketDataQuery({
    id: name,
    days,
  });

  const {
    currentData: audienceProfileData,
    isLoading: isAudienceProfileLoading,
    isFetching: isAudienceProfileFetching,
    isSuccess: isSuccessAudienceProfile,
    refetch,
  } = coinApi.useGetTokenAudienceProfileQuery(
    {
      id: pathname.split('/')[2],
      audience: filterOption,
    },
    {
      skip: !pathname?.includes('coin'),
    },
  );

  const {
    data: volume,
    isLoading: isLoadingVolume,
  } = coinApi.useGetCoinFromMarketDataQuery({
    id: name,
    days: '30',
  });

  const { data: bitcoin } = coinApi.useGetCoinFromMarketDataQuery({
    id: 'bitcoin',
    days,
  });

  const {
    data: holders,
    isLoading: isLoadingHolders,
    isFetching: isFetchingHolders,
  } = coinApi.useGetCoinHoldersQuery(name);

  const {
    data: reach,
    isLoading: isReachLoading,
    isFetching: isReachFetching,
  } = coinApi.useReachTokenQuery(name);

  useEffect(() => {
    if (isSuccessAudienceProfile) {
      refetch();
    }
  }, [refetch, filterOption]);

  useEffect(() => {
    if (volume && !volume.error) {
      setDailyVolumeData(volume?.total_volumes[0][1]);
      setDailyVolumeDataChart(volume?.total_volumes);
    }
  }, [volume]);

  useEffect(() => {
    const supportedChains = supportedBlockchains(asset?.platforms);
    if (asset && supportedChains.length === 1 && !isLoading && !isFetching) {
      const networksArr = supportedChains.map((platform) => platform);
      setNetworks(networksArr);
    }
  }, [asset, isLoading, isFetching]);

  useEffect(() => {
    if (historyIsLoading) {
      dispatch(historyPrices(undefined));
    }
    if (history) {
      dispatch(historyPrices(history));
    }
    if (!history && isSuccess) {
      dispatch(historyPrices(null));
    }
  }, [dispatch, history, historyIsFetching, historyIsLoading, isSuccess]);

  useEffect(() => {
    if (days === '30' && historyVolume) {
      dispatch(dailyVolume(historyVolume));
    }
  }, [days, dispatch, historyVolume]);

  useEffect(() => {
    if (bitcoin) {
      dispatch(bitcoinPrice(bitcoin));
    }
  }, [bitcoin, dispatch]);

  useEffect(() => {
    const tabName = searchParams.get('tab')?.replace('+', ' ') || DEFAULT_TAB;
    const holdersTabs = ['current', 'relevant'];
    const isHoldersTab = tabName === 'Holders';
    const shouldSwitchToHolders = holdersTabs.includes(filterOption) && tabName === 'List';
    const shouldSwitchToList = !holdersTabs.includes(filterOption) && isHoldersTab;
    if (shouldSwitchToHolders || shouldSwitchToList) {
      const switchLabel = shouldSwitchToHolders ? 'Holders' : 'List';
      setSelectedTab(getPage(switchLabel));
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: '1',
        tab: switchLabel,
      });
    }
    setSelectedTab(pages.length ? getPage(tabName) : null);
  }, [searchParams, pages, filterOption]);

  useEffect(() => {
    if (isLoading || isFetching) {
      setTitleSectionData(null);
      setIsSupportBlockchain(null);
    }
    if (asset) {
      setTitleSectionData({
        id: asset.id,
        logo: asset.image.large,
        name: asset.name,
        symbol: asset.symbol.toUpperCase(),
        tags: asset.categories.filter((category) => category !== null).slice(0, 5),
        icons: assetData.icons,
        links: asset.links,
        platforms: supportedBlockchains(asset.platforms),
        blockchain: asset.platforms && Object.keys(asset.platforms).length ? asset.platforms : null,
      });

      const platformsSupporting = supportedBlockchains(asset.platforms).length > 0;

      setIsSupportBlockchain(platformsSupporting);

      setMarketData({
        id: asset.id,
        widgetData: [
          {
            title: 'Price',
            value: getPrice(asset?.market_data?.current_price?.usd),
            icon: Price,
            growth: asset?.market_data?.current_price?.usd && asset?.market_data?.price_change_percentage_7d
              ? getGrowth(
                asset?.market_data?.current_price?.usd,
                (asset?.market_data?.current_price?.usd || 0)
                / (1 + ((asset?.market_data?.price_change_percentage_7d || 0) / 100)),
                getPrice((asset?.market_data?.current_price?.usd || 0)
                / (1 + ((asset?.market_data?.price_change_percentage_7d || 0) / 100))),
              ) : null,
          },
          {
            title: 'Market Cap',
            value: getCardValue(asset.market_data.market_cap.usd, true),
            icon: MarketCap,
          },
          {
            title: 'Circulating Supply',
            value: getEthNumber(asset.market_data.circulating_supply, true),
            icon: Box,
          },
          {
            title: 'Relevant Holders',
            value: getCardValue(holders?.relevant_holder_count),
            icon: Members,
            info: 'Holders owning at least 5$ worth of this token',
            isLoading: isLoadingHolders,
            growth: getGrowth(
              holders?.relevant_holder_count,
              holders?.previous_values?.relevant_holder_count,
              getFormatedNumber(holders?.previous_values?.relevant_holder_count),
            ),
          },
        ],
        tokenMarketData: {
          ath: asset?.market_data?.ath?.usd,
          atl: asset?.market_data?.atl?.usd,
          current_price: asset?.market_data?.current_price?.usd,
        },
        priceData: {
          day: asset.market_data.price_change_percentage_24h,
          week: asset.market_data.price_change_percentage_7d,
          month: asset.market_data.price_change_percentage_30d,
          twoMonth: asset.market_data.price_change_percentage_60d,
          year: asset.market_data.price_change_percentage_1y,
        },
        infoData: [
          {
            title: 'Daily Volume',
            value: Object.keys(asset.market_data.total_volume).length && asset.market_data.total_volume.usd !== null
              ? ethFormat(asset.market_data.total_volume.usd)
              : '?',
            chart: dailyVolumeDataChart,
            isLoading: isLoadingVolume,
          },
          {
            title: 'Holders',
            value: holders && holders.holder_count !== null ? convertNumber(holders.holder_count) : '?',
            isLoading: isLoadingHolders,
            growth: getGrowth(
              holders?.holder_count,
              holders?.previous_values?.holder_count,
              getFormatedNumber(holders?.previous_values?.holder_count),
            ),
          },
          {
            title: 'Monthly Active Addresses',
            value: holders && holders.monthly_active_addresses !== null
              ? convertNumber(holders.monthly_active_addresses)
              : '?',
            isLoading: isLoadingHolders,
            growth: getGrowth(
              holders?.monthly_active_addresses,
              holders?.previous_values?.monthly_active_addresses,
              getFormatedNumber(holders?.previous_values?.monthly_active_addresses),
            ),
          },
        ],
      });

      setPages([
        {
          label: 'Audience Profile',
          component: (
            <InvestorProfile
              holders={audienceProfileData?.wallet_count}
              symbol={asset.symbol}
              isSupportedBlockchain={platformsSupporting}
              audienceProfileData={audienceProfileData}
              isAudienceProfileLoading={isAudienceProfileLoading || isAudienceProfileFetching}
              networks={networks}
              user={user}
              userLoading={userLoading}
            />),
          id: 1,
        },
        {
          label: 'Audience Portfolio',
          component: (
            <InvestPortfolio
              holders={audienceProfileData?.wallet_count}
              isSupportedBlockchain={platformsSupporting}
            />),
          id: 2,
        },
        {
          label: ['current', 'relevant'].includes(filterOption) ? 'Holders' : 'List',
          component: (
            <Holders
              isSupportedBlockchain={platformsSupporting}
            />),
          id: 3,
        },
        {
          label: 'Dapp Usage',
          component: (
            <DappUsage
              holders={audienceProfileData?.wallet_count}
              isSupportedBlockchain={platformsSupporting}
            />),
          id: 4,
        },
        {
          label: 'Centralized Exchanges',
          component: (
            <Exchanges
              isSupportedBlockchain={platformsSupporting}
            />
          ),
          id: 5,
        },
        // { label: 'Markets', component: <ComingSoon />, id: 4 },
        // { label: 'News', component: <ComingSoon />, id: 5 },
        // { label: 'Social', component: <ComingSoon />, id: 6 },
        // { label: 'Contract', component: <ComingSoon />, id: 7 },
        // { label: 'Competitors', component: <ComingSoon />, id: 8 },
      ]);
    }
  }, [
    asset,
    dailyVolumeData,
    dailyVolumeDataChart,
    holders,
    isFetchingHolders,
    isLoading,
    isFetching,
    isLoadingHolders,
    isLoadingVolume,
    audienceProfileData,
    isAudienceProfileLoading,
    isAudienceProfileFetching,
  ]);

  useEffect(() => {
    if (pages?.length && navType !== 'POP') {
      dispatch(setTabPages(pages));
    }
  }, [dispatch, navType, pages]);

  useEffect(() => {
    if (searchParams.get('scroll') && navType === 'POP') {
      setTimeout(
        () => window.scrollTo({ top: +searchParams.get('scroll'), left: 0, behavior: 'smooth' }),
        100,
      );
    }
  }, [searchParams.get('scroll')]);

  useEffect(() => () => {
    dispatch(resetSelectedAsset());
    dispatch(setTabPages([]));
    setIsSupportBlockchain(null);
  }, [dispatch]);

  const tabContent = useMemo(() => {
    if (typeof isSupportBlockchain === 'boolean') {
      return (
        <>
          <Filters />
          <div className="asset-section m-o mt-3">
            <div className="title-gap asset-navigation">
              <ul className="nav nav-pills gap-2">
                {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((elem) => (
                  <li
                    role="presentation"
                    className="nav-item cursor-pointer"
                    key={elem.id}
                    onClick={() => {
                      setSelectedTab(getPage(elem.label));
                      setSearchParams({
                        ...Object.fromEntries([...searchParams]),
                        tab: elem.label,
                        scroll: '',
                        page: '1',
                        orderBy: '',
                      });
                    }}
                  >
                    <Link
                      className={`nav-link ${elem.label === selectedTab?.label ? 'active' : ''}`}
                      to={`${pathname}${search}`}
                    >
                      {elem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="dropdown-divider w-100" />
            {selectedTab?.component}
          </div>
        </>
      );
    }
    return null;
  }, [isSupportBlockchain, selectedTab, navType, pages, pathname, search, searchParams, setSearchParams, tabPages]);

  return (
    <>
      {!(isLoading || isFetching) && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {asset.name}
            {' '}
            Insights
            {' '}
            - Absolute Labs Platform
          </title>
        </Helmet>
      )}
      <SingleCoinTitle
        isAssetLoading={isLoading || isFetching}
        singleCoinData={titleSectionData}
      />
      <div className="asset-section mt-3">
        <Market
          isAssetLoading={isLoading || isFetching}
          isHoldersLoading={isLoadingHolders || isFetchingHolders}
          marketData={marketData.tokenMarketData}
          id={marketData.id}
          widgetData={marketData.widgetData || [{}, {}, {}, {}]}
          infoData={marketData.infoData}
          priceData={marketData.priceData}
          setDays={setDays}
          days={days}
          isLoading={historyIsLoading}
          isFetching={historyIsFetching}
          isReachLoading={isReachLoading || isReachFetching || isLoadingHolders || isFetchingHolders}
          holders={reach?.profile_count}
          reach={reach}
          type="coin"
          symbol={asset?.symbol}
          networks={networks}
          dataAiBlock={aiOverview}
          user={user}
          userLoading={userLoading}
          name={asset?.name}
        />
      </div>
      {tabContent}
    </>
  );
};

export default Asset;
