export const getTokenUnit = (chain) => {
  switch (chain) {
    case 'ethereum':
      return 'ETH';
    case 'near':
      return 'NEAR';
    case 'polygon':
      return 'MATIC';
    case 'chiliz':
      return "CHZ";
    case 'flow':
      return "FLOW";
    case 'ordinals':
      return "BTC";
    default:
      return 'ETH';
  }
};